<template>
  <div class="overlay open" @click="close"></div>
  <div class="modalCompany edit-power">
    <div class="close" @click="close">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.6581 1.33776L6.99582 6L11.6581 10.6622C11.79 10.7941 11.8641 10.973 11.8641 11.1595C11.8641 11.3461 11.79 11.525 11.6581 11.6569C11.5262 11.7887 11.3473 11.8628 11.1608 11.8628C10.9742 11.8628 10.7953 11.7887 10.6635 11.6569L6.00121 6.99461L1.33897 11.6569C1.20707 11.7887 1.02819 11.8628 0.841661 11.8628C0.655135 11.8628 0.476248 11.7887 0.344354 11.6569C0.21246 11.525 0.138364 11.3461 0.138364 11.1595C0.138364 10.973 0.212461 10.7941 0.344355 10.6622L5.0066 6L0.344355 1.33776C0.212461 1.20586 0.138364 1.02698 0.138364 0.840451C0.138364 0.653925 0.21246 0.47504 0.344354 0.343146C0.476248 0.211252 0.655134 0.137155 0.841661 0.137154C1.02819 0.137155 1.20707 0.211252 1.33897 0.343145L6.00121 5.00539L10.6635 0.343146C10.7953 0.211252 10.9742 0.137155 11.1608 0.137155C11.3473 0.137155 11.5262 0.211252 11.6581 0.343146C11.79 0.47504 11.8641 0.653925 11.8641 0.840452C11.8641 1.02698 11.79 1.20586 11.6581 1.33776Z"
          fill="#052C57" />
      </svg>
    </div>
    <div class="title">
      <svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M35.3073 17.4709C35.3839 17.4709 35.4604 17.4632 35.537 17.4517L41.9765 16.3222C42.053 16.3069 42.1258 16.2724 42.1794 16.215L50.8745 7.51823C50.91 7.48281 50.9382 7.44074 50.9574 7.39442C50.9766 7.3481 50.9865 7.29845 50.9865 7.2483C50.9865 7.19815 50.9766 7.1485 50.9574 7.10218C50.9382 7.05586 50.91 7.01379 50.8745 6.97837L44.5117 0.611036C44.4389 0.538288 44.3432 0.5 44.2398 0.5C44.1365 0.5 44.0408 0.538288 43.968 0.611036L35.2729 9.30782C35.2154 9.36525 35.181 9.43417 35.1657 9.51075L34.0363 15.9508C33.999 16.1559 34.0123 16.367 34.075 16.5659C34.1377 16.7647 34.248 16.9452 34.3961 17.0918C34.6488 17.3369 34.9666 17.4709 35.3073 17.4709ZM37.8877 10.7934L44.2398 4.44369L47.0461 7.25021L40.6939 13.5999L37.2904 14.2011L37.8877 10.7934Z" fill="#0349A8"/>
          <path d="M11.2156 6.7C6.79738 6.7 3.21563 10.2818 3.21563 14.7V40.3C3.21563 44.7182 6.79738 48.3 11.2156 48.3H36.8156C41.2339 48.3 44.8156 44.7182 44.8156 40.3V22.7C44.8156 21.8163 45.5319 21.1 46.4156 21.1C47.2993 21.1 48.0156 21.8163 48.0156 22.7V40.3C48.0156 46.4856 43.0012 51.5 36.8156 51.5H11.2156C5.03003 51.5 0.015625 46.4856 0.015625 40.3V14.7C0.015625 8.51441 5.03003 3.5 11.2156 3.5H28.8156C29.6993 3.5 30.4156 4.21634 30.4156 5.1C30.4156 5.98366 29.6993 6.7 28.8156 6.7H11.2156Z" fill="#0349A8"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6156 19.5C17.6156 15.9654 20.481 13.1 24.0156 13.1C27.5503 13.1 30.4156 15.9654 30.4156 19.5C30.4156 23.0346 27.5503 25.9 24.0156 25.9C20.481 25.9 17.6156 23.0346 17.6156 19.5ZM24.0156 16.3C22.2483 16.3 20.8156 17.7327 20.8156 19.5C20.8156 21.2673 22.2483 22.7 24.0156 22.7C25.7829 22.7 27.2156 21.2673 27.2156 19.5C27.2156 17.7327 25.7829 16.3 24.0156 16.3Z" fill="#0349A8"/>
          <path d="M11.2156 38.7C11.2156 32.5144 16.23 27.5 22.4156 27.5H25.6156C31.8012 27.5 36.8156 32.5144 36.8156 38.7V40.3C36.8156 41.1837 36.0993 41.9 35.2156 41.9C34.3319 41.9 33.6156 41.1837 33.6156 40.3V38.7C33.6156 34.2818 30.0339 30.7 25.6156 30.7V35.5C25.6156 36.3837 24.8993 37.1 24.0156 37.1C23.1319 37.1 22.4156 36.3837 22.4156 35.5V30.7C17.9974 30.7 14.4156 34.2818 14.4156 38.7V40.3C14.4156 41.1837 13.6993 41.9 12.8156 41.9C11.9319 41.9 11.2156 41.1837 11.2156 40.3V38.7Z" fill="#0349A8"/>
      </svg>
      <div>{{ $t('Add Role') }} </div>
    </div>
    <Form ref="myForm" class="form-contact scroll row mt-3" @submit="UpdateRole" :validation-schema="schema">
      <div class="col-md-6 mb-3">
      <Field name="name_en" type="text" :placeholder="$t('Role Name(en)')" />
      <ErrorMessage name="name_en" class="error-message" />
    </div>
    <div class="col-md-6 mb-3">
      <Field name="name_ar" type="text" :placeholder="$t('Role Name(ar)')" />
      <ErrorMessage name="name_ar" class="error-message" />
    </div>
          <label class="label-feild mb-3" style="color: #052C57;">{{ $t('Choose the permissions') }}</label>
          <div class="col-md-6 mb-3" v-for="item of Permission" :key="item.id">
           <label :for="`power-${item.id}`" class="power-item-select" :class="[Selected.includes(item.name) ? 'active':'']">
            <Field v-model="Selected" :value="item.name" type="checkbox" class="contact-field" :id="`power-${item.id}`" name="Employee_Power"
            :placeholder="$t('CompanyName')" />
            <span >{{ lang== 'en' ? item.show_name.en : item.show_name.ar }}</span>
           </label>
          </div>
          <ErrorMessage name="Employee_Power" class="error-message" />
        <div class="footer-card">
          <button class="btn-third border-0" @click="ClearAll">{{ $t('Clear_all') }}</button>
          <button class="btn-main" type="submit">{{ $t('Add Role') }}</button>
        </div>
      </Form>
  </div>
</template>
<script>
import { notify } from "@kyvg/vue3-notification";
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Cookie from 'cookie-universal';
const cookie = Cookie();
export default {
  data() {
    return {
      Selected:[],
      Permission:[],
      role_ar:'',
      role_en:''
    }
  },
  props: {
    EmployeeObj: Object,
  },
  setup() {

    configure({
      validateOnInput: true,
    });

    const schema = yup.object({
      name_en: yup
      .string()
      ,
      name_ar: yup
      .string()

    });
    return {
      schema,

    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    close() {
      this.$emit('hide')
    },
    ClearAll() {
      this.$refs.myForm.resetForm(); // Reset the form fields
    },
    UpdateRole(values) {
      const FData = new FormData();
      FData.append('show_name[en]', values.name_en);
      FData.append('show_name[ar]', values.name_ar);
      this.Selected.forEach((item,key)=>{
          FData.append(`permissions[${key}]`, item);

        })
      axios
        .post(`/company/dashboard/roles`, FData, {
          headers: {
            "Authorization": `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            notify({
              type: "success",
              text: this.$i18n.locale == 'ar' ? 'تم إضافة الدور بنجاح' : 'The role has been added successfully.',
            });
            this.$emit('hide');
          }
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },

  GetPermissioms(){
            axios.get(`/company/dashboard/permissions`, {
                headers: {
                    "Authorization": `Bearer ${cookie.get('CompanyToken')}`
                }
            })
                .then((res) => {
                    this.Permission = res.data.data.Permission;
                })
                .catch(function (error) {
                    notify({
                        type: "error",
                        text: error.response.data.message,
                    });
                });
        },
  },

  mounted() {
    this.GetPermissioms()
    // this.Selected=this.EmployeeObj.role.name
  }

};
</script>
